/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { GrLinkNext } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import momWithHerBaby from 'assets/images/mom-with-her-baby.webp';
import momCarryingBaby from 'assets/images/mom-carrying-baby.webp';
import momoKissingBaby from 'assets/images/mom-kissing-baby.webp';
import postpartumShape from 'assets/images/postpartum-shape.webp';
import postpartumShapeBg from 'assets/images/pregnancy-shape-bg.webp';
import smile from 'assets/images/smile.svg';
import tablet from 'assets/images/tablet.svg';
import headphones from 'assets/images/headphones.svg';
import { useGetParams } from '../utils/useGetParams';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { ROUTES } from 'utils/constants';
import { KEYS } from '../utils/constants';
import { handleTrackEvent } from '../utils/handleTrackEvent';
import { useEvieOnboardingContext } from '../EvieOnboardingProvider';

import InfoScreen from '../InfoScreen';
import UserNameScreen from '../UserNameScreen';
import QuizStarter from '../QuizStarter';
import SolidButton from 'components/SolidButton';

const PostpartumPath = ({ startSurvey }) => {
  const { t } = useTranslation();

  const { params } = useEvieOnboardingContext();
  const [path] = useLocalStorage(KEYS.PATH);

  const { step, push } = useGetParams();

  const handleNext = () => {
    handleTrackEvent('Step clicked', { number: step + 1 });
    push(`${ROUTES.EVIE_ONBOARDING}?${params}&step=${step + 1}`);
  };

  return (
    <>
      {step === 2 && (
        <InfoScreen staticimg>
          <LazyLoadImage effect="opacity" src={momWithHerBaby} alt="Mom with her baby" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={smile} alt="smily" />
            </div>
            <h2 className="info-screen__footer_title">{t('evieOnboarding.infoScreen.postpartum.title1')}</h2>
            <p className="info-screen__footer_content">{t('evieOnboarding.infoScreen.postpartum.description1')}</p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 3 && (
        <InfoScreen staticimg>
          <LazyLoadImage effect="opacity" src={momCarryingBaby} alt="Mom carry baby" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={tablet} alt="tablet" />
            </div>
            <h2 className="info-screen__footer_title">{t('evieOnboarding.infoScreen.postpartum.title2')}</h2>
            <p className="info-screen__footer_content">{t('evieOnboarding.infoScreen.postpartum.description2')}</p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 4 && (
        <InfoScreen staticimg>
          <LazyLoadImage effect="opacity" src={momoKissingBaby} alt="Mom kissing baby" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={headphones} alt="headphones" />
            </div>
            <h2 className="info-screen__footer_title">{t('evieOnboarding.infoScreen.postpartum.title3')}</h2>
            <p className="info-screen__footer_content">{t('evieOnboarding.infoScreen.postpartum.description3')}</p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 5 && <UserNameScreen />}
      {step === 6 && (
        <QuizStarter background="postpartum">
          <section className="quiz-starter__content">
            <div className="quiz-starter__postpartum">
              <img src={postpartumShapeBg} alt="plant" className="quiz-starter__postpartum_bg" />
              <img src={postpartumShape} alt="woman with baby" className="quiz-starter__postpartum_img" />
            </div>
            <h2 className="quiz-starter__content_title">{t('evieOnboarding.quizStarter.title2')}</h2>
            <p className="quiz-starter__content_description">{t('evieOnboarding.quizStarter.description')}</p>
          </section>
          <section className="quiz-starter__footer">
            <SolidButton className="evie-survey" onClick={() => startSurvey(path)}>
              {t('evieOnboarding.quizStarter.button')}
            </SolidButton>
          </section>
        </QuizStarter>
      )}
    </>
  );
};

PostpartumPath.propTypes = {
  startSurvey: PropTypes.func.isRequired,
};

export default PostpartumPath;
