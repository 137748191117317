import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, pathOr } from 'ramda';
import Loader from 'react-loader-spinner';
import { useMutation } from '@apollo/react-hooks';

import { GET_MEDIA_FILES } from 'apollo/queries';
import { CREATE_MEDIA_FILE } from 'apollo/mutations';
import { useRedirectByRole } from 'utils/hooks/useRedirectByRole';
import { ROLES } from 'utils/constants';
import { useAuthQuery, useFormInput } from 'utils/hooks';
import { useToggle } from 'utils/hooks/useToggle';
import noTemplates from 'assets/images/no-data.svg';

import LeftMenu from 'components/LeftMenu';
import Header from 'components/Header';
import SearchFilter from 'components/SearchFilter';
import SolidButton from 'components/SolidButton';
import Thumb from 'components/Thumb';
import CreateMediaFile from 'components/assets/CreateMediaFile';
import { NoMembers } from 'components/EmptyState';
import NoProgramsFound from 'components/Forms/NoFormsFound';

const Assets = () => {
  useRedirectByRole([ROLES.SUPER_CLINIC]);
  const { t } = useTranslation();

  const { loading, data, refetch } = useAuthQuery(GET_MEDIA_FILES);
  const { mediaFiles: allAssets = [] } = { ...data };
  const [createMediaFile] = useMutation(CREATE_MEDIA_FILE);

  const [assets, setAssets] = useState([]);
  const [isCreateAsset, setIsCreateAsset] = useToggle();
  const [spinner, setSpinner] = useToggle();

  const searchAssets = useFormInput('');
  const { value = '' } = { ...searchAssets };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    let temporal = allAssets;
    if (value) {
      temporal = allAssets.filter((asset) => {
        const { title } = asset;
        return `${title}`.toLowerCase().includes(value.toLowerCase());
      });
    }
    setAssets(temporal);
  }, [allAssets, value]);

  const handleCreateMediaFile = async (values) => {
    setSpinner();
    const response = await createMediaFile({
      variables: { ...values },
    });

    const errors = pathOr([], ['data', 'createMediaFile', 'errors'])(response);
    const emptyErrors = isEmpty(errors);

    setSpinner();
    setIsCreateAsset();

    if (emptyErrors) {
      refetch();
    }
  };

  return (
    <>
      <section className="d-flex">
        <LeftMenu />
        <div className="dashboard__main">
          <Header title={t('leftMenuHeader.assets')} shadow />
          <div className="dashboard__content--patients">
            {!loading && isEmpty(allAssets) && (
              <NoMembers
                title={t('assets.emptyState.title')}
                subtitle={t('assets.emptyState.content')}
                button={t('assets.btn')}
                handleClick={setIsCreateAsset}
                image={noTemplates}
              />
            )}
            {!loading && !isEmpty(allAssets) && (
              <div className="forms">
                <div className="forms__search">
                  <SearchFilter searchItems={searchAssets} placeholder={t('searchLabel.asset')} />
                  <SolidButton onClick={setIsCreateAsset} className="slim">
                    {t('assets.btn')}
                  </SolidButton>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '30%' }}>{t('tableHeader.image')}</th>
                      <th style={{ width: '70%' }}>{t('tableHeader.title')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(assets || []).map((asset) => {
                      const { id, title, thumbUrl, altText = 'thumb' } = asset;
                      return (
                        <tr key={id} className="">
                          <td style={{ width: '30%', display: 'flex', justifyContent: 'center' }}>
                            <Thumb source={thumbUrl} alt={altText} />
                          </td>
                          <td style={{ width: '70%', display: 'flex', justifyContent: 'center' }}>{title}</td>
                        </tr>
                      );
                    })}
                    {isEmpty(assets) && <NoProgramsFound />}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      {isCreateAsset && (
        <CreateMediaFile
          modalIsOpen={isCreateAsset}
          toggleModal={setIsCreateAsset}
          createMediaFile={handleCreateMediaFile}
        />
      )}
      <Loader
        className="spinner"
        type="ThreeDots"
        color="#495fd7"
        height={100}
        width={100}
        visible={loading || spinner}
      />
    </>
  );
};

export default Assets;
