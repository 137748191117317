import { useEffect } from 'react';

export const useTabSettings = (title, faviconPath) => {
  useEffect(() => {
    document.title = title;
    const link = document.querySelector("link[rel='icon']") || document.createElement('link');
    link.rel = 'icon';
    link.href = faviconPath;
    document.head.appendChild(link);
  }, [title, faviconPath]);
};
