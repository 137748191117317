import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';

import Modal from 'components/Modal';
import SolidButton from 'components/SolidButton';
import { TextInput, ImageInput } from 'components/FormComponents';

const modalProps = {
  content: {
    width: '95%',
    maxWidth: '514px',
    padding: 24,
  },
};

const CreateMediaFile = ({ modalIsOpen, toggleModal, createMediaFile }) => {
  const { t } = useTranslation();

  return (
    <Modal modalProps={modalProps} modalIsOpen={modalIsOpen} closeModal={toggleModal} closeable={false}>
      <h3 className="builder__title_modal-label">{t('assets.createTitle')}</h3>
      <Formik initialValues={{ title: '', file: '' }} onSubmit={createMediaFile} enableReinitialize>
        {({ setFieldValue, isSubmitting, values }) => {
          const fields = Object.values(values).every(Boolean);
          const isDisabledButton = [!fields, isSubmitting].includes(true);
          return (
            <Form>
              <ImageInput setFieldValue={setFieldValue} formatedImage="" name="file" label={t('formLabel.image')} />
              <Field type="text" label={t('formLabel.title')} name="title" component={TextInput} />
              <div className="builder__title_btns">
                <SolidButton type="button" className="secondary mr-2" onClick={toggleModal}>
                  {t('button.cancel')}
                </SolidButton>
                <SolidButton disabled={isDisabledButton} className={isDisabledButton ? 'disabled' : ''} type="submit">
                  {t('button.create')}
                </SolidButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

CreateMediaFile.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  createMediaFile: PropTypes.func.isRequired,
};

export default CreateMediaFile;
