import {
  GET_CLINIC_PROFILE,
  GET_COMPANY_PROFILE,
  GET_SUPER_CLINIC_PROFILE,
  GET_PATIENTS,
  GET_EMPLOYEES,
  GET_CLINIC_AVAILABILITIES,
  GET_SUPER_CLINIC_AVAILABILITIES,
  GET_CLINIC_TEAM_MEMBERS,
  GET_SUPER_CLINIC_TEAM_MEMBERS,
  GET_CLINIC_LANGUAGE,
  GET_COMPANY_LANGUAGE,
  GET_SUPER_CLINIC_LANGUAGE,
  GET_COMPANY_TERMS_AND_THUMB,
  GET_SUPER_CLINIC_NOTI_AND_THUMB,
  GET_CLINIC_NOTI_AND_THUMB,
  GET_PATIENT,
  GET_EMPLOYEE,
} from 'apollo/queries';

export const ROUTES = {
  HOME: '/',
  CONFIGURATION: '/configuracion',
  EDIT_PROFILE: '/editar-perfil',
  EMPLOYEES: '/empleados',
  INDIVIDUALS: '/individuals',
  REWARDS: '/recompensas',
  ORDERS: '/orders',
  CALENDAR: '/calendario',
  AVAILABILITY: '/calendario/disponibilidad',
  BILLING: '/billing',
  PRIVACY: '/privacidad',
  TERMS: '/terminos',
  ONBOARDING: '/induccion',
  DASHBOARD: '/dashboard',
  PATIENTS: '/pacientes',
  REPORTS: '/reportes',
  PATIENT: '/paciente',
  CREATE_PATIENT: '/crear-paciente',
  PLANS: '/planes',
  COVENANTS: '/convenios',
  CREATE_PLAN: '/crear-plan',
  PLAN: '/plan',
  LIBRARY: '/biblioteca',
  FORMS: '/formularios',
  EVENTS: '/eventos',
  CREATE_FORM: '/crear-formulario',
  SURVEYS: '/encuestas',
  CLINICS: '/clinicas',
  COMPANIES: '/empresas',
  SUPER_CLINIC: '/super-clinic',
  SPECIALIST: '/specialist',
  TEMPLATES: '/plantillas',
  TEMPLATE: '/plantilla',
  CREATE_TEMPLATE: '/crear-plantilla',
  READINGS: '/readings',
  READING: '/reading',
  CREATE_READING: '/create-reading',
  PROGRAMS: '/programs',
  PROGRAM: '/program',
  CREATE_PROGRAM: '/create-program',
  COMPANY: '/company',
  CREATE_COMPANY: '/create-company',
  COMPANIES_LIST: '/companies-list',
  CREATE_NOTIFICATION: '/create-notification',
  CHAT: '/chat',
  EDIT_PATIENT: '/editar-paciente',
  EDIT_PLAN: '/editar-plan',
  ACTIVE_PAUSES: '/active-breaks',
  CREATE_ACTIVE_PAUSE: '/create-active-break',
  ACTIVE_PAUSE: '/active-break',
  CREATE_AUDIO: '/create-audio',
  AUDIO: '/audio',
  AUDIO_LIST: '/audio-list',
  INVOICES: '/invoices',
  SHORT_VIDEOS: '/short-videos',
  SHORT_VIDEO: '/short-video',
  CREATE_SHORT_VIDEO: '/create-short-video',
  EVIE_PROGRAMS: '/evie-programs',
  EVIE_PROGRAM: '/evie-program',
  CREATE_EVIE_PROGRAM: '/create-evie-program',
  SQUEEZES: '/squeezes',
  SQUEEZE: '/squeeze',
  CREATE_SQUEEZE: '/create-squeeze',
  EVIE_ONBOARDING: '/onboarding',
  EVIE_DOWNLOAD: '/download',
  ASSETS: '/assets',
};

export const PAYMENT_MODE = {
  SUBSCRIPTION: 'subscription',
  REIMBURSEMENT: 'reimbursement',
  ONDEMAND: 'ondemand',
};

export const ROLES = {
  CLINIC: 'Clinic',
  COMPANY: 'Company',
  SUPER_CLINIC: 'SuperClinic',
  SPECIALIST: 'Specialist',
};

export const USER_ROLES = {
  [ROUTES.CLINICS]: 'clinic',
  [ROUTES.COMPANIES]: 'company',
  [ROUTES.SUPER_CLINIC]: 'super_clinic',
  [ROUTES.SPECIALIST]: 'specialist',
};

export const REDIRECT_BY_ROLE = {
  [ROLES.CLINIC]: ROUTES.ONBOARDING,
  [ROLES.COMPANY]: ROUTES.EMPLOYEES,
  [ROLES.SUPER_CLINIC]: ROUTES.PLANS,
  [ROLES.SPECIALIST]: ROUTES.PATIENTS,
};

export const FETCH_BY_ROLE = {
  [ROLES.CLINIC]: GET_CLINIC_PROFILE,
  [ROLES.COMPANY]: GET_COMPANY_PROFILE,
  [ROLES.SUPER_CLINIC]: GET_SUPER_CLINIC_PROFILE,
  [ROLES.SPECIALIST]: GET_CLINIC_PROFILE,
};

export const FETCH_LANGUAGE_BY_ROLE = {
  [ROLES.CLINIC]: GET_CLINIC_LANGUAGE,
  [ROLES.COMPANY]: GET_COMPANY_LANGUAGE,
  [ROLES.SUPER_CLINIC]: GET_SUPER_CLINIC_LANGUAGE,
  [ROLES.SPECIALIST]: GET_CLINIC_LANGUAGE,
};

export const FETCH_NOTI_AND_THUMB_BY_ROLE = {
  [ROLES.CLINIC]: GET_CLINIC_NOTI_AND_THUMB,
  [ROLES.COMPANY]: GET_COMPANY_TERMS_AND_THUMB,
  [ROLES.SUPER_CLINIC]: GET_SUPER_CLINIC_NOTI_AND_THUMB,
  [ROLES.SPECIALIST]: GET_CLINIC_NOTI_AND_THUMB,
};

export const GET_PARTNER_BY_ROLE = {
  [ROLES.CLINIC]: 'getClinicInfo',
  [ROLES.COMPANY]: 'getCompanyInfo',
  [ROLES.SUPER_CLINIC]: 'getSuperClinicInfo',
  [ROLES.SPECIALIST]: 'getClinicInfo',
};

export const FETCH_USERS_BY_ROLE = {
  [ROLES.CLINIC]: GET_PATIENTS,
  [ROLES.SUPER_CLINIC]: GET_EMPLOYEES,
  [ROLES.SPECIALIST]: GET_PATIENTS,
};

export const GET_USERS_BY_ROLE = {
  [ROLES.CLINIC]: 'patients',
  [ROLES.SUPER_CLINIC]: 'employees',
  [ROLES.SPECIALIST]: 'patients',
};

export const FETCH_AVAILABILITIES_BY_ROLE = {
  [ROLES.CLINIC]: GET_CLINIC_AVAILABILITIES,
  [ROLES.SUPER_CLINIC]: GET_SUPER_CLINIC_AVAILABILITIES,
  [ROLES.SPECIALIST]: GET_CLINIC_AVAILABILITIES,
};

export const FETCH_TEAM_MEMBERS_BY_ROLE = {
  [ROLES.CLINIC]: GET_CLINIC_TEAM_MEMBERS,
  [ROLES.SUPER_CLINIC]: GET_SUPER_CLINIC_TEAM_MEMBERS,
  [ROLES.SPECIALIST]: GET_CLINIC_TEAM_MEMBERS,
};

export const FETCH_USER_BY_ROLE = {
  [ROLES.CLINIC]: GET_PATIENT,
  [ROLES.SUPER_CLINIC]: GET_EMPLOYEE,
  [ROLES.SPECIALIST]: GET_PATIENT,
};

export const GET_USER_BY_ROLE = {
  [ROLES.CLINIC]: 'patient',
  [ROLES.SUPER_CLINIC]: 'employee',
  [ROLES.SPECIALIST]: 'patient',
};

export const PARAM_ID_BY_ROLE = {
  [ROLES.CLINIC]: 'patientId',
  [ROLES.SUPER_CLINIC]: 'employeeId',
  [ROLES.SPECIALIST]: 'patientId',
};

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};
