import React, { useEffect } from 'react';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { useGetParams } from 'components/evieOnboarding/utils/useGetParams';
import { KEYS } from 'components/evieOnboarding/utils/constants';
import { ROUTES } from 'utils/constants';
import { useTabSettings } from 'utils/hooks/useTabSettings';
import { useFcPixel } from 'utils/hooks/useFcPixel';

import Layout from 'components/evieOnboarding/Layout';
import ThankyouScreen from 'components/evieOnboarding/ThankyouScreen';

const EvieDownload = () => {
  useTabSettings('Evie', '/evie-favicon.ico');

  const { push } = useGetParams();

  const [userToken] = useLocalStorage(KEYS.USER_TOKEN);
  const [entitlements] = useLocalStorage(KEYS.ENTITLEMENTS);

  useFcPixel();

  useEffect(() => {
    if (!entitlements || !userToken) {
      push(ROUTES.EVIE_ONBOARDING);
    }
  }, [userToken, entitlements]);

  return (
    <Layout>
      <ThankyouScreen background="postpartum" />
    </Layout>
  );
};

export default EvieDownload;
