import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import signupLogo from 'assets/images/signup-logo.webp';
import qr from 'assets/images/qr-evie-download.webp';

const ThankyouScreen = ({ background }) => {
  const { t } = useTranslation();

  return (
    <section className={`info-screen quiz-starter thankyou-screen ${background}`}>
      <img className="username-screen__form_logo thankyou-screen_logo" src={signupLogo} alt="Evie logo" />
      <div>
        <h2 className="thankyou-screen_title">{t('evieOnboarding.thankyouScreen.title')}</h2>
        <p className="thankyou-screen_content">{t('evieOnboarding.thankyouScreen.content')}</p>
        <li className="thankyou-screen__steps">
          <ol>{t('evieOnboarding.thankyouScreen.steps.one')}</ol>
          <ol>{t('evieOnboarding.thankyouScreen.steps.two')}</ol>
        </li>
        <a
          className="thankyou-screen__steps_link"
          href="https://hievie.onelink.me/g6dj/uzk92mo5"
          target="_blank"
          rel="noreferrer"
        >
          {t('evieOnboarding.thankyouScreen.btn')}
        </a>
        <p className="thankyou-screen_content">{t('evieOnboarding.thankyouScreen.steps.qr')}</p>
        <img src={qr} alt="QR to download Evie app" className="thankyou-screen_qr" />
      </div>
    </section>
  );
};

ThankyouScreen.propTypes = {
  background: PropTypes.string.isRequired,
};

export default ThankyouScreen;
