import React from 'react';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import heartLoader from 'assets/heart-loader.json';

const LoadingScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="loading-screen">
      <Lottie animationData={heartLoader} loop style={{ width: 140, height: 110 }} />
      <section className="loading-screen__content">
        <h2 className="loading-screen__content_title">{t('evieOnboarding.loadingScreen.title')}</h2>
        <p className="loading-screen__content_description">{t('evieOnboarding.loadingScreen.content')}</p>
      </section>
    </div>
  );
};

export default LoadingScreen;
