import React, { useState, useEffect } from 'react';
import { Purchases } from '@revenuecat/purchases-js';
import PropTypes from 'prop-types';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { ENVIRONMENT } from 'utils/constants';
import { CONDITIONS, KEYS, IDENTIFIERS } from '../utils/constants';
import { useGetParams } from '../utils/useGetParams';
import {
  REACT_APP_ENV,
  REACT_APP_REVENUECAT_BILLING_PUBLIC_API_KEY,
  REACT_APP_REVENUECAT_BILLING_SANDBOX_API_KEY,
} from '../../../config';

import PregnancyPlan from '../PregnancyPlan';
import PostpartumPlan from '../PostpartumPlan';
import PainPlan from '../PainPlan';

const Plans = ({ userId, path }) => {
  const { identifier } = useGetParams();

  const [selectedPath] = useLocalStorage(KEYS.PATH);
  const [selectedId] = useLocalStorage(KEYS.USER_ID);
  const [discountIdentifier, setDiscountIdentifier] = useLocalStorage(KEYS.IDENTIFIER);

  const [offeringList, setOfferingList] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);

  useEffect(() => {
    setDiscountIdentifier(identifier);
  }, [identifier]);

  useEffect(() => {
    const fetchOfferings = async () => {
      const apiKey =
        REACT_APP_ENV === ENVIRONMENT.DEVELOPMENT
          ? REACT_APP_REVENUECAT_BILLING_SANDBOX_API_KEY
          : REACT_APP_REVENUECAT_BILLING_PUBLIC_API_KEY;
      const offerIdentifier = identifier || discountIdentifier || IDENTIFIERS.FULL;
      try {
        await Purchases.configure(apiKey, userId || selectedId);
        const offerings = await Purchases.getSharedInstance().getOfferings();
        if (offerings.all[offerIdentifier].availablePackages.length !== 0) {
          setOfferingList(offerings.all[offerIdentifier].availablePackages);
        }
      } catch (error) {
        console.error('Error fetching offerings: ', error);
      }
    };
    if (userId || selectedId) {
      fetchOfferings();
    }
  }, [userId, selectedId]);

  useEffect(() => {
    if (offeringList.length > 0) {
      const findYearlyOffer = offeringList.find((offer) => offer.identifier === '$rc_annual');
      setSelectedOffer(findYearlyOffer);
    }
  }, [offeringList]);

  switch (path || selectedPath) {
    case CONDITIONS.PREGNANCY:
      return (
        <PregnancyPlan offerings={offeringList} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer} />
      );
    case CONDITIONS.POSTPARTUM:
      return (
        <PostpartumPlan offerings={offeringList} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer} />
      );
    case CONDITIONS.INCONTINENCE:
    case CONDITIONS.SEXUAL:
      return <PainPlan offerings={offeringList} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer} />;
    default:
      return null;
  }
};

Plans.defaultProps = {
  userId: null,
  path: null,
};

Plans.propTypes = {
  userId: PropTypes.string,
  path: PropTypes.string,
};

export default Plans;
