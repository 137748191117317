import React, { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { KEYS } from 'components/evieOnboarding/utils/constants';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { useGetParams } from 'components/evieOnboarding/utils/useGetParams';

const EvieOnboardingContext = createContext();

const EvieOnboardingProvider = ({ children }) => {
  const { urlParams, step } = useGetParams();

  const [params, setParams] = useLocalStorage(KEYS.URL_PARAMS, urlParams);

  useEffect(() => {
    if (!params && step === 0 && !urlParams.includes('plan_step')) {
      setParams(urlParams);
    }
  }, []);

  return <EvieOnboardingContext.Provider value={{ params, setParams }}>{children}</EvieOnboardingContext.Provider>;
};

EvieOnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useEvieOnboardingContext() {
  const context = useContext(EvieOnboardingContext);
  if (!context) {
    throw new Error('Expected an EvieOnboardingContext somewhere in the react tree to set context value');
  }
  return context;
}

export { useEvieOnboardingContext, EvieOnboardingProvider };
