/* eslint-disable import/order */
import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/constants';
import { handleTrackEvent } from '../utils/handleTrackEvent';
import { useEvieOnboardingContext } from '../EvieOnboardingProvider';
import check from 'assets/images/evie-check-1.svg';
import topknot from 'assets/images/evie-topknot.svg';
import bell from 'assets/images/evie-bell.svg';
import star from 'assets/images/evie-star.svg';

import SolidButton from 'components/SolidButton';

import { useGetParams } from '../utils/useGetParams';

const getTrialEndDate = (daysFromNow, language) => {
  const trialEndDate = new Date();
  trialEndDate.setDate(trialEndDate.getDate() + daysFromNow);

  return trialEndDate.toLocaleDateString(language, { month: 'long', day: 'numeric' });
};

const TrialScreen = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { goBack, push, planStep } = useGetParams();
  const { params } = useEvieOnboardingContext();

  const handleNext = () => {
    handleTrackEvent('Step plan', { number: planStep + 1 });
    push(`${ROUTES.EVIE_ONBOARDING}?${params}&plan_step=${planStep + 1}`);
  };

  return (
    <div className="trial-screen">
      <div className="info-screen__goback">
        <IoMdArrowBack className="info-screen__goback_icon" onClick={goBack} />
      </div>
      <section className="trial-screen__header">
        <div className="trial-screen__header_bg" />
      </section>
      <section className="trial-screen__content">
        <h2 className="trial-screen__content_title">{t('evieOnboarding.trialScreen.title')}</h2>
        <div className="trial-screen__steps firstchild">
          <section className="trial-screen__icons firstchild">
            <span className="trial-screen__icons_progress" />
            <img src={check} alt="check icon" />
          </section>
          <section className="trial-screen__contentsteps">
            <p className="trial-screen__contentsteps_title firstchild">
              {t('evieOnboarding.trialScreen.steps.stepOne.title')}
            </p>
            <p className="trial-screen__contentsteps_content">
              {t('evieOnboarding.trialScreen.steps.stepOne.content')}
            </p>
          </section>
        </div>
        <div className="trial-screen__steps">
          <section className="trial-screen__icons">
            <span className="trial-screen__icons_progress" />
            <img src={topknot} alt="topknot icon" />
          </section>
          <section className="trial-screen__contentsteps secondchild">
            <p className="trial-screen__contentsteps_title">{t('evieOnboarding.trialScreen.steps.stepTwo.title')}</p>
            <p className="trial-screen__contentsteps_content">
              {t('evieOnboarding.trialScreen.steps.stepTwo.content')}
            </p>
          </section>
        </div>
        <div className="trial-screen__steps">
          <section className="trial-screen__icons">
            <img src={bell} alt="bell icon" />
          </section>
          <section className="trial-screen__contentsteps">
            <p className="trial-screen__contentsteps_title">{t('evieOnboarding.trialScreen.steps.stepThree.title')}</p>
            <p className="trial-screen__contentsteps_content">
              {t('evieOnboarding.trialScreen.steps.stepThree.content')}
            </p>
          </section>
        </div>
        <div className="trial-screen__steps">
          <section className="trial-screen__icons">
            <img src={star} alt="star icon" />
          </section>
          <section className="trial-screen__contentsteps">
            <p className="trial-screen__contentsteps_title">{t('evieOnboarding.trialScreen.steps.stepFour.title')}</p>
            <p className="trial-screen__contentsteps_content">
              {t('evieOnboarding.trialScreen.steps.stepFour.content')} {getTrialEndDate(7, language)}
            </p>
          </section>
        </div>
      </section>
      <section className="trial-screen__footer">
        <SolidButton className="evie-survey" onClick={handleNext}>
          {t('evieOnboarding.perkScreen.button')}
        </SolidButton>
      </section>
    </div>
  );
};

export default TrialScreen;
