import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';

import { GET_MEDIA_FILES } from 'apollo/queries';
import { useAuthQuery } from 'utils/hooks';

import Modal from 'components/Modal';
import Thumb from 'components/Thumb';
import SolidButton from 'components/SolidButton';

import './media-file-selector.scss';

const modalProps = {
  content: {
    overflow: 'hidden',
    width: '95%',
    maxWidth: '514px',
    padding: 24,
  },
};

const MediaFileSelector = ({ modalIsOpen, toggleModal, setFieldValue }) => {
  const { t } = useTranslation();

  const { loading, data } = useAuthQuery(GET_MEDIA_FILES);
  const { mediaFiles: allMediaFiles = [] } = { ...data };

  const [mediaFiles, setMediaFiles] = useState([]);
  const [selectedMediaFile, setSeledMediaFile] = useState(null);
  console.log('MEDIA: ', selectedMediaFile);

  useEffect(() => {
    if (!isEmpty(allMediaFiles)) {
      setMediaFiles(allMediaFiles);
    }
  }, [allMediaFiles]);

  const handleSetValue = () => {
    setFieldValue('gif', selectedMediaFile.fileUrl);
    toggleModal();
  };

  return (
    <Modal modalProps={modalProps} modalIsOpen={modalIsOpen} closeModal={toggleModal} closeable={false}>
      {loading && <div>...loading</div>}
      <header className="mediafileselector__header">
        <h2 className="mediafileselector__header_title">{t('assets.selectTitle')}</h2>
      </header>
      <section className="mediafileselector__content">
        {mediaFiles.map((mediaFile) => {
          const { id = '', title = '', thumbUrl = '', altText = 'thumb' } = mediaFile;
          const isSelected = selectedMediaFile?.id === id;
          return (
            <button
              key={id}
              type="button"
              className={`mediafileselector__content_card ${isSelected ? 'selected' : ''}`}
              onClick={() => setSeledMediaFile(mediaFile)}
            >
              <Thumb source={thumbUrl} alt={altText} />
              <p className="mediafileselector__content_title">{title}</p>
            </button>
          );
        })}
      </section>
      <div className="builder__title_btns">
        <SolidButton type="button" className="secondary mr-2" onClick={toggleModal}>
          {t('button.cancel')}
        </SolidButton>
        <SolidButton
          disabled={!selectedMediaFile}
          className={!selectedMediaFile ? 'disabled' : ''}
          onClick={handleSetValue}
        >
          {t('button.add')}
        </SolidButton>
      </div>
    </Modal>
  );
};

MediaFileSelector.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default MediaFileSelector;
