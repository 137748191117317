/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'swiper/css';

import pregnant from 'assets/images/pregnant.webp';
import pregnancyShape from 'assets/images/pregnancy-shape.webp';
import smile from 'assets/images/smile.svg';
import checkMark from 'assets/images/check-mark.svg';
import strawberry from 'assets/images/strawberry.svg';
import bodyShape from 'assets/images/body-shape.svg';
import plant from 'assets/images/plant.svg';
import heart from 'assets/images/multi-heart.svg';
import linearGradientBg from 'assets/images/linear-gradient-bg.webp';
import meliAndBaby from 'assets/images/meli-and-baby.webp';
import angie from 'assets/images/angie.webp';
import kathe from 'assets/images/kathe.webp';
import evi from 'assets/images/evi.webp';
import { useGetParams } from '../utils/useGetParams';

import PerkScreen from '../PerkScreen';
import InfoScreen from '../InfoScreen';
import Offerings from '../Offerings';
import TrialScreen from '../TrialScreen';

const PregnancyPlan = ({ offerings, selectedOffer, setSelectedOffer }) => {
  const { t } = useTranslation();
  const { planStep } = useGetParams();

  return (
    <>
      {planStep === 1 && (
        <PerkScreen>
          <LazyLoadImage effect="opacity" src={pregnant} alt="pregnant woman" className="info-screen__bgimg perks" />
          <section className="info-screen__footer perks">
            <div className="info-screen__footer_topbg plan" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon perks">
              <img src={pregnancyShape} alt="silhouette of pregnant woman" />
            </div>
            <h2 className="info-screen__footer_title">{t('evieOnboarding.perkScreen.title1')}</h2>
            <p className="info-screen__footer_content perks">{t('evieOnboarding.perkScreen.description1')}</p>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={smile} alt="smily" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.physio')}</p>
            </div>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={checkMark} alt="checkmark" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.chat')}</p>
            </div>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={strawberry} alt="strawberry" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.exercises')}</p>
            </div>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={bodyShape} alt="body shape" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.routines')}</p>
            </div>
            <div className="perk-screen__perkcard">
              <img className="perk-screen__perkcard_icon" src={plant} alt="plant" />
              <p className="perk-screen__perkcard_content">{t('evieOnboarding.perkScreen.perks.audio')}</p>
            </div>
          </section>
        </PerkScreen>
      )}
      {planStep === 2 && <TrialScreen />}
      {planStep === 3 && (
        <InfoScreen>
          <Swiper speed={600} slidesPerView={1} autoplay={{ delay: 4000 }} modules={[Autoplay]} loop>
            <SwiperSlide>
              <section className="info-screen__testimonials">
                <div className="info-screen__testimonial">
                  <div>
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                  </div>
                  <p className="info-screen__testimonial_content">
                    {t('evieOnboarding.planScreen.testimonials.pregnancy.meli.content')}
                  </p>
                  <p className="info-screen__testimonial_author">
                    {t('evieOnboarding.planScreen.testimonials.pregnancy.meli.author')}
                  </p>
                </div>
                <img
                  className="info-screen__bgimg testimonialbg"
                  src={linearGradientBg}
                  alt="linear gradient background"
                />
                <img
                  className="info-screen__bgimg info-screen__testimonials_img"
                  src={meliAndBaby}
                  alt="Woman and baby"
                />
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="info-screen__testimonials">
                <div className="info-screen__testimonial">
                  <div>
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                  </div>
                  <p className="info-screen__testimonial_content">
                    {t('evieOnboarding.planScreen.testimonials.pregnancy.angie.content')}
                  </p>
                  <p className="info-screen__testimonial_author">
                    {t('evieOnboarding.planScreen.testimonials.pregnancy.angie.author')}
                  </p>
                </div>
                <img
                  className="info-screen__bgimg testimonialbg"
                  src={linearGradientBg}
                  alt="linear gradient background"
                />
                <img className="info-screen__bgimg info-screen__testimonials_img" src={angie} alt="Woman and baby" />
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="info-screen__testimonials">
                <div className="info-screen__testimonial">
                  <div>
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                  </div>
                  <p className="info-screen__testimonial_content">
                    {t('evieOnboarding.planScreen.testimonials.pregnancy.kathe.content')}
                  </p>
                  <p className="info-screen__testimonial_author">
                    {t('evieOnboarding.planScreen.testimonials.pregnancy.kathe.author')}
                  </p>
                </div>
                <img
                  className="info-screen__bgimg testimonialbg"
                  src={linearGradientBg}
                  alt="linear gradient background"
                />
                <img className="info-screen__bgimg info-screen__testimonials_img" src={kathe} alt="Woman and baby" />
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="info-screen__testimonials">
                <div className="info-screen__testimonial">
                  <div>
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                    <FaStar className="info-screen__testimonial_star" />
                  </div>
                  <p className="info-screen__testimonial_content">
                    {t('evieOnboarding.planScreen.testimonials.pregnancy.evi.content')}
                  </p>
                  <p className="info-screen__testimonial_author">
                    {t('evieOnboarding.planScreen.testimonials.pregnancy.evi.author')}
                  </p>
                </div>
                <img
                  className="info-screen__bgimg testimonialbg"
                  src={linearGradientBg}
                  alt="linear gradient background"
                />
                <img className="info-screen__bgimg info-screen__testimonials_img" src={evi} alt="Pregnant woman" />
              </section>
            </SwiperSlide>
          </Swiper>
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img className="multi-heart-icon" src={heart} alt="heart" />
            </div>
            <h2 className="info-screen__footer_title plan-screen">{t('evieOnboarding.planScreen.title')}</h2>
            <Offerings offerings={offerings} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer} />
          </section>
        </InfoScreen>
      )}
    </>
  );
};

PregnancyPlan.defaultProps = {
  selectedOffer: null,
};

PregnancyPlan.propTypes = {
  offerings: PropTypes.arrayOf(PropTypes.shape({ identifier: PropTypes.string.isRequired })).isRequired,
  selectedOffer: PropTypes.shape({ identifier: PropTypes.string.isRequired }),
  setSelectedOffer: PropTypes.func.isRequired,
};

export default PregnancyPlan;
