import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidateSignup = () => {
  const { t } = useTranslation();

  const evieSignup = object().shape({
    email: string().trim().email(t('inputValidation.email')).required(t('inputValidation.required')),
    /* password: string()
      .trim()
      .required(t('inputValidation.required'))
      .matches(/^.{8,40}$/, t('inputValidation.password')), */
  });

  return evieSignup;
};
