import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { KEYS, IDENTIFIERS } from '../utils/constants';

function formatPrice(amount, currency = 'USD', byMonth = false) {
  const price = amount / 100; // Convert cents to dollars
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(byMonth ? price / 12 : price);
}

const OfferingCard = ({ offer, selectedOffer, setSelectedOffer }) => {
  const { t } = useTranslation();
  const {
    identifier,
    rcBillingProduct: {
      currentPrice: { currency, amount },
    },
  } = offer;

  const [discountIdentifier] = useLocalStorage(KEYS.IDENTIFIER);

  const isSelected = selectedOffer && selectedOffer.identifier === identifier;
  const isAnnual = offer.identifier === '$rc_annual';
  const title = isAnnual
    ? t('evieOnboarding.planScreen.offering.annual')
    : t('evieOnboarding.planScreen.offering.monthly');
  const isDiscount = discountIdentifier === IDENTIFIERS.THIRTY;

  return (
    <button
      type="button"
      onClick={() => setSelectedOffer(offer)}
      className={`plan-screen__offeringcard ${isSelected ? 'selected' : ''}`}
    >
      <div className={`plan-screen__offeringcard_trial ${isSelected ? 'selected' : ''}`}>
        <span>{t('evieOnboarding.planScreen.offering.trial')}</span>
      </div>
      {isDiscount ? (
        <div className="plan-screen__offeringcard_trial discount selected">
          <span>{t('evieOnboarding.planScreen.offering.discount')}</span>
        </div>
      ) : null}
      <div className="plan-screen__offeringcard_pricing">
        <span className={`radiobutton ${isSelected ? 'selected' : ''}`} />
        {isDiscount ? (
          <p className="discount-offering-price">
            {isAnnual ? formatPrice((amount * 100) / 70, currency, true) : formatPrice((amount * 100) / 70, currency)}
            /mo
          </p>
        ) : null}
        <p className={`offering-price ${isDiscount ? 'bottom-price' : ''}`}>
          {isAnnual ? formatPrice(amount, currency, true) : formatPrice(amount, currency)}
          <span className="offering-price-label">/mo</span>
        </p>
      </div>
      <div className="plan-screen__offeringcard_description">
        <p className="plan-screen__offeringcard_periodicity">{title}</p>
        {isAnnual ? (
          <p className="plan-screen__offeringcard_periodicity billed">
            {t('evieOnboarding.planScreen.offering.billed.annual')}{' '}
            {isDiscount ? (
              <span className="discount-offering-price">{formatPrice((amount * 100) / 70, currency)}</span>
            ) : null}
            {formatPrice(amount, currency)}
          </p>
        ) : (
          <p className="plan-screen__offeringcard_periodicity billed">
            {t('evieOnboarding.planScreen.offering.billed.monthly')}
          </p>
        )}
      </div>
    </button>
  );
};

OfferingCard.defaultProps = {
  selectedOffer: null,
};

OfferingCard.propTypes = {
  offer: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    rcBillingProduct: PropTypes.shape({
      currentPrice: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  selectedOffer: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
  }),
  setSelectedOffer: PropTypes.func.isRequired,
};

export default OfferingCard;
