/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'utils/constants';
import { useGetParams } from '../utils/useGetParams';
import { handleTrackEvent } from '../utils/handleTrackEvent';
import { useEvieOnboardingContext } from '../EvieOnboardingProvider';

import SolidButton from 'components/SolidButton';

const PerkScreen = ({ children }) => {
  const { t } = useTranslation();
  const { planStep, push } = useGetParams();
  const { params } = useEvieOnboardingContext();

  const handleNext = () => {
    handleTrackEvent('Step plan', { number: planStep + 1 });
    push(`${ROUTES.EVIE_ONBOARDING}?${params}&plan_step=${planStep + 1}`);
  };

  return (
    <section className="perk-screen">
      {children}
      <SolidButton className="evie-survey username-screen__form_nextbtn handle-sections" onClick={handleNext}>
        {t('evieOnboarding.perkScreen.button')}
      </SolidButton>
    </section>
  );
};

PerkScreen.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PerkScreen;
