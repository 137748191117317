import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { getIn } from 'formik';
import { FaArchive } from 'react-icons/fa';

import { useToggle } from 'utils/hooks/useToggle';

import MediaFileSelector from 'components/assets/MediaFileSelector';

import './form.scss';

const InfoScreenInput = ({
  field,
  form,
  label,
  labelClassName,
  className,
  containerClass,
  isFSblocked,
  isRequired,
  type,
  ...props
}) => {
  const name = propOr('', 'name')(field);
  const errors = propOr({}, 'errors')(form);
  const touched = propOr({}, 'touched')(form);
  const error = getIn(errors, name);
  const hasError = getIn(touched, name) && error;
  const invalidClass = hasError ? 'is-invalid' : '';
  const fsClass = isFSblocked ? 'fs-block' : '';

  const [selectMediaFile, setSelectMediaFile] = useToggle();

  return (
    <>
      <div className={`form__input-container ${containerClass}`}>
        {label && (
          <label htmlFor={name} className={`form__label ${labelClassName}`}>
            {label} {isRequired && <span className="text-pink">*</span>}
          </label>
        )}
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <input
            className={`form__input ${className} ${fsClass} ${invalidClass}`}
            id={name}
            type={type}
            {...field}
            {...props}
          />
          <FaArchive size={20} color="#8093fc" onClick={setSelectMediaFile} />
        </div>
        {hasError && <div className="form__error">{error}</div>}
      </div>

      {selectMediaFile && (
        <MediaFileSelector
          modalIsOpen={selectMediaFile}
          toggleModal={setSelectMediaFile}
          setFieldValue={form.setFieldValue}
        />
      )}
    </>
  );
};

InfoScreenInput.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClass: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({ setFieldValue: PropTypes.func }).isRequired,
  isFSblocked: PropTypes.bool,
  isRequired: PropTypes.bool,
};

InfoScreenInput.defaultProps = {
  label: '',
  className: '',
  labelClassName: '',
  containerClass: '',
  isFSblocked: false,
  isRequired: false,
  type: 'text',
};

export default InfoScreenInput;
