export const CONDITIONS = {
  PREGNANCY: 'pregnancy',
  POSTPARTUM: 'postpartum',
  INCONTINENCE: 'incontinence',
  SEXUAL: 'sexual',
};

export const ONBOARDING_STATUS = {
  WELCOME: 'welcome',
  SURVEY: 'survey',
  SIGN_UP: 'signUp',
  PLANS: 'plans',
  THANKYOU: 'thankyou',
};

export const KEYS = {
  PATH: 'path',
  CONDITIONS: 'conditions',
  USER_NAME: 'userName',
  ONBOARDING_STATUS: 'onboardingStatus',
  CONTENT: 'content',
  USER_TOKEN: 'userToken',
  USER_ID: 'userId',
  SOURCE: 'source',
  ENTITLEMENTS: 'entitlements',
  IDENTIFIER: 'identifier',
  URL_PARAMS: 'urlParams',
  USER_EMAIL: 'userEmail',
};

export const IDENTIFIERS = {
  FULL: 'evie-program',
  THIRTY: 'evie-program-discount',
};
