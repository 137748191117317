/* eslint-disable-next-line */
import gql from 'graphql-tag';

const planSelector = `
  id
  status
  completed
  currentDay {
    id
    weekNumber
    weekTitle
  }
  plan {
    id
    description
    title
    planType
    bodyParts {
      id
      image
      title
    }
    numberOfDays
    numberOfExercises
    numberOfWeeks
    weeks {
      id
      days {
        id
        weekNumber
        exercises {
          id
          title
          image
        }
      }
    }
  }
`;

export const BODY_PARTS = gql`
  query bodyPartsQuery {
    bodyParts {
      id
      title
      image
    }
  }
`;

export const PLAN_BY_BODY_PART = gql`
  query planByBodyPartQuery($bodyPartId: String!) {
    planByBodyPart(bodyPartId: $bodyPartId) {
      id
      title
      image
      owner
    }
  }
`;

export const GET_PATIENTS = gql`
  query patients {
    patients {
      id
      email
      name
      gender
      birthday
      cardId
      status
      covenant {
        label
        name
      }
      assessment {
        id
        content
        diagnostic
        objective
        exerciseLevel
        functionality
        duration
        medicalHistory
        painLevel
        painLocalization
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query patient($patientId: String!) {
    patient(patientId: $patientId) {
      id
      email
      phone
      name
      gender
      birthday
      cardId
      status
      teamMember {
        email
        id
        name
      }
      covenant {
        label
        name
        id
      }
      bookings {
        id
        message
        startAt
        status
        versions {
          id
          logs
        }
      }
      currentBooking {
        id
        status
        startAt
      }
      assessment {
        id
        content
        diagnostic
        objective
        exerciseLevel
        functionality
        duration
        medicalHistory
        painLevel
        painLocalization
        answerFiles {
          id
          label
          file {
            original
          }
        }
      }
      patientFollowups {
        id
        content
        painLevel
        pillsLevel
        urinaryDisfunction
        functionality
        sleepQuality
        observations
        owner
        createdAt
        form {
          id
          title
          typeForm
        }
        answerFiles {
          id
          label
          file {
            original
          }
        }
      }
      surveys {
        id
        content
        form {
          id
          title
          createdAt
          content
        }
        status
        typeSurvey
        url
      }
      patientSurveys {
        id
        content
        form {
          id
          title
          createdAt
          content
        }
        answerFiles {
          id
          label
          file {
            original
          }
        }
        status
        typeSurvey
        url
        groupPoints {
          id
          points
        }
        totalPoints
      }
      patientPlans {
        id
        currentDay {
          id
        }
        plan {
          id
          description
          title
          bodyParts {
            id
            image
            title
          }
          numberOfDays
          numberOfExercises
          numberOfWeeks
          weeks {
            id
            days {
              id
              weekNumber
              exercises {
                id
                title
                image
              }
            }
          }
        }
        status
      }
    }
  }
`;

export const SUGGESTED_EXERCISES = gql`
  query suggestedExercises($patientId: String!) {
    suggestedExercises(patientId: $patientId) {
      id
      tips
      title
      image
      levels
      bodyParts
      equipments
      video
      reps
      rest
      sets
      instructions
    }
  }
`;

export const EXERCISES = gql`
  query exercises {
    exercises {
      id
      tips
      title
      image
      levels
      bodyParts
      equipments
      video
      reps
      rest
      sets
      instructions
    }
  }
`;

export const GET_PLANS = gql`
  query planTemplates {
    planTemplates {
      id
      title
      image
      description
      owner
      ownerType
      sharedTemplate
      bodyParts {
        id
        title
        image
      }
      numberOfDays
      numberOfExercises
      numberOfWeeks
    }
  }
`;

export const GET_PLAN = gql`
  query plan($planId: String!) {
    plan(planId: $planId) {
      id
      title
      image
      description
      owner
      ownerType
      sharedTemplate
      planType
      bodyParts {
        id
        title
        image
      }
      weeks {
        id
        days {
          id
          weekNumber
          exercises {
            id
            tips
            title
            image
            levels
            bodyParts
            equipments
            video
            reps
            rest
            sets
            instructions
          }
          dayExercises {
            id
            exercisePosition
            rules
          }
        }
      }
    }
  }
`;

export const VALIDATE_USER = gql`
  query getClinicInfo {
    getClinicInfo {
      id
      email
      name
      termsConditions
      tcAcceptedAt
      trialDaysLeft
      trialActive
      googleToken
      availabilities {
        weekDay
        intervals
      }
      patients {
        id
        name
      }
      plans {
        id
        title
      }
      forms {
        id
        title
      }
      profile {
        id
        currency
        hasCalls
        avatar {
          thumb
        }
      }
    }
  }
`;

export const GET_CLINIC_PROFILE = gql`
  query getClinicInfo {
    getClinicInfo {
      id
      email
      name
      trialDaysLeft
      tcAcceptedAt
      termsConditions
      trialActive
      teamMembers {
        id
        email
        name
      }
      availabilities {
        weekDay
        intervals
      }
      profile {
        address
        hasCalls
        avatar {
          original
          thumb
        }
        colors
        description
        doctorsNumber
        country
        city
        id
        nit
        currency
        phone
        language
        paymentMode
      }
      notifications {
        createdAt
        id
        params
        readAt
        recipientId
        recipientType
        type
      }
    }
  }
`;

export const GET_BOOKINGS = gql`
  query clinicDashboard {
    clinicDashboard {
      patientStatus
      awaitingResponseBookings {
        startAt
        id
        patient {
          id
          name
          email
          gender
        }
      }
      upcommingBookings {
        startAt
        id
        patient {
          id
          name
          email
          gender
        }
      }
      completedDaysToday
      last7DaysSatisfactionLevel
      surveysStats
    }
  }
`;

export const GET_PATIENT_STATS = gql`
  query patientStats($patientId: String!) {
    patientStats(patientId: $patientId) {
      activities {
        createdAt
        id
        key
        parameters
        recipient {
          id
          feedback {
            id
          }
          currentPlan {
            id
            title
          }
          planNumber
          weekNumber
        }
      }
      activitiesByDay
      averageDifficultyLevel
      averageSatisfactionLevel
      currentDay
      currentPainLevel
      currentWeek
      lastFinishedDay
      painLevels
    }
  }
`;

export const GET_FEMALE_PROGRAM_STATS = gql`
  query employeeStats($patientId: String!) {
    patientStats(patientId: $patientId) {
      activitiesByDay
      averageDifficultyLevel
      averageSatisfactionLevel
      currentDay
      currentPainLevel
      currentWeek
      lastFinishedDay
      painLevels
    }
  }
`;

export const GET_COVENANTS = gql`
  query covenants {
    covenants {
      id
      name
      label
      description
      active
    }
  }
`;

export const GET_EVENTS = gql`
  query events {
    events {
      conferenceLink
      covenant {
        id
        name
        label
      }
      description
      endAt
      id
      startAt
      title
    }
  }
`;

export const GET_CUSTOMER_PORTAL = gql`
  query getCustomerPortal {
    getCustomerPortal {
      url
    }
  }
`;

export const GET_FORMS = gql`
  query getClinicForms($type: String) {
    getClinicForms(type: $type) {
      id
      createdAt
      title
      typeForm
      content
    }
  }
`;

export const GET_FORM = gql`
  query getClinicForm($formId: String!) {
    getClinicForm(formId: $formId) {
      id
      title
      typeForm
      surveyType
      content
    }
  }
`;

export const PATIENT_SURVEY = gql`
  query patientSurvey($surveyId: String!) {
    patientSurvey(surveyId: $surveyId) {
      form {
        id
        title
        content
      }
      patient {
        id
        email
      }
      url
      typeSurvey
      status
    }
  }
`;

export const VALID_USER = gql`
  query validUser($email: String!, $token: String!, $role: String!) {
    validUser(email: $email, token: $token, role: $role)
  }
`;

export const GET_COMPANY_PROFILE = gql`
  query getCompanyInfo {
    getCompanyInfo {
      id
      invitationLink
      name
      email
      trialDaysLeft
      tcAcceptedAt
      termsConditions
      trialActive
      activeEmployees
      cardLast4
      cardBrand
      paymentMethodType
      companySpendLimit {
        allowanceAmount
        id
        cadence
      }
      profile {
        id
        language
        nit
        description
        address
        phone
        paymentMode
        country
        currency
        city
        contactPerson
        numberOfEmployees
        paymentMode
        avatar {
          original
          thumb
        }
      }
      employees {
        id
        name
        email
        status
        gender
        currentSpendLimitAmount
        companyAllowanceAmount
        invoiceItems {
          description
          amount
          createdAt
        }
      }
    }
  }
`;

export const GET_SUPER_CLINIC_PROFILE = gql`
  query getSuperClinicInfo {
    getSuperClinicInfo {
      id
      name
      email
      teamMembers {
        id
        email
        name
      }
      profile {
        id
        description
        hasCalls
        avatar {
          original
          thumb
        }
      }
      forms {
        id
        title
        typeForm
        surveyType
      }
      notifications {
        createdAt
        id
        params
        readAt
        recipientId
        recipientType
        type
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products {
    products {
      id
      name
      description
      avatar {
        original
      }
      status
      quantity
      points
    }
  }
`;

export const VALIDATE_TOKEN = gql`
  query validateToken($token: String!) {
    validateToken(token: $token)
  }
`;

export const GET_COMPANY_LANGUAGE = gql`
  query getCompanyLanguage {
    getCompanyInfo {
      id
      profile {
        id
        language
      }
    }
  }
`;

export const GET_CLINIC_LANGUAGE = gql`
  query getClinicLanguage {
    getClinicInfo {
      id
      profile {
        id
        language
      }
    }
  }
`;

export const GET_SUPER_CLINIC_LANGUAGE = gql`
  query getSuperClinicLanguage {
    getSuperClinicInfo {
      id
      profile {
        id
        language
      }
    }
  }
`;

export const GET_CLINIC_READINGS = gql`
  query getClinicReadings {
    getClinicReadings {
      id
      title
      blockContent
      backgroundImage {
        thumb
      }
      contentJson
    }
  }
`;

export const GET_CLINIC_READING = gql`
  query getClinicReading($readingId: String!) {
    getClinicReading(readingId: $readingId) {
      id
      title
      blockContent
      content
      contentJson
      backgroundImage {
        thumb
      }
      thumbnailImage {
        thumb
      }
    }
  }
`;

export const GET_COMPANY_ORDERS = gql`
  query companyOrders {
    companyOrders {
      id
      name
      status
      createdAt
      employee {
        name
        email
      }
      lineItem {
        title
        quantity
        points
        product {
          avatar {
            original
            thumb
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_INFO = gql`
  query getProductInfo($productId: String!) {
    getProductInfo(productId: $productId)
  }
`;

export const GET_READING_TEMPLATES = gql`
  query readingTemplates {
    readingTemplates {
      id
      title
      description
      readings {
        id
      }
      backgroundImage {
        thumb
      }
    }
  }
`;

export const GET_READING_TEMPLATE = gql`
  query readingTemplate($templateId: String!) {
    readingTemplate(templateId: $templateId) {
      id
      title
      description
      readings {
        id
        title
        backgroundImage {
          thumb
        }
      }
      backgroundImage {
        thumb
      }
    }
  }
`;

export const GET_PROGRAMS = gql`
  query programs {
    programs {
      id
      title
      doctorName
      duration
      backgroundImage {
        thumb
      }
    }
  }
`;

export const GET_PROGRAM = gql`
  query program($programId: String!) {
    program(programId: $programId) {
      id
      title
      description
      doctorName
      duration
      readingDays
      backgroundImage {
        thumb
      }
      doctorImage {
        thumb
      }
      planTemplate {
        id
      }
      readingTemplate {
        id
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query companies {
    companies {
      id
      name
      employees {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query company($companyId: String!) {
    company(companyId: $companyId) {
      id
      name
      email
      configValues
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query employees {
    employees {
      id
      email
      name
      gender
      birthday
      cardId
      status
      covenant {
        label
        name
      }
      assessment {
        id
        content
        diagnostic
        objective
        exerciseLevel
        functionality
        duration
        medicalHistory
        painLevel
        painLocalization
      }
      company {
        id
        name
      }
    }
  }
`;

export const GET_INDIVIDUALS = gql`
  query individuals {
    individuals {
      id
      email
      name
      gender
      birthday
      cardId
      status
      assessment {
        id
        content
        diagnostic
        objective
        exerciseLevel
        functionality
        duration
        medicalHistory
        painLevel
        painLocalization
      }
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query employee($employeeId: String!) {
    employee(employeeId: $employeeId) {
      id
      email
      phone
      name
      gender
      birthday
      cardId
      status
      femaleProgram
      company {
        id
        name
      }
      teamMember {
        email
        id
        name
      }
      covenant {
        label
        name
        id
      }
      bookings {
        id
        message
        startAt
        status
        versions {
          id
          logs
        }
      }
      currentBooking {
        id
        status
        startAt
      }
      assessment {
        id
        content
        diagnostic
        objective
        exerciseLevel
        functionality
        duration
        medicalHistory
        painLevel
        painLocalization
        answerFiles {
          id
          label
          file {
            original
          }
        }
      }
      patientFollowups {
        id
        content
        painLevel
        pillsLevel
        urinaryDisfunction
        functionality
        sleepQuality
        observations
        owner
        createdAt
        form {
          id
          title
          typeForm
        }
        answerFiles {
          id
          label
          file {
            original
          }
        }
      }
      surveys {
        id
        content
        form {
          id
          title
          createdAt
          content
        }
        status
        typeSurvey
        url
      }
      patientSurveys {
        id
        content
        form {
          id
          title
          createdAt
          content
        }
        answerFiles {
          id
          label
          file {
            original
          }
        }
        status
        typeSurvey
        url
        groupPoints {
          id
          points
        }
        totalPoints
      }
      patientPlans {
        ${planSelector}
      }
      allPatientPlans {
        ${planSelector}
      }
      patientPrograms {
        id
        status
        currentLevelNumber
        currentSessionNumber
        lastSessionCompletedAt
        program {
          id
          title
          duration
        }
      }
    }
  }
`;

export const GET_COUPON = gql`
  query checkStripeCoupon($couponId: String!) {
    checkStripeCoupon(couponId: $couponId) {
      coupon
      errors
    }
  }
`;

export const GET_TWILIO_TOKEN = gql`
  query getSuperClinicInfo($duration: Int) {
    getSuperClinicInfo {
      id
      email
      twilioToken(duration: $duration)
    }
  }
`;

export const GET_SURVEYS_STATS = gql`
  query clinicDashboard($companyId: String) {
    clinicDashboard {
      surveysStats(companyId: $companyId)
    }
  }
`;

export const GET_CLINIC_AVAILABILITIES = gql`
  query getClinicInfo {
    getClinicInfo {
      id
      profile {
        id
        timezoneValue
        timezoneOffset
      }
      availabilities {
        id
        weekDay
        intervals
      }
    }
  }
`;

export const GET_SUPER_CLINIC_AVAILABILITIES = gql`
  query getSuperClinicInfo {
    getSuperClinicInfo {
      id
      profile {
        id
        timezoneValue
        timezoneOffset
      }
      availabilities {
        id
        weekDay
        intervals
      }
    }
  }
`;

export const GET_CLINIC_TEAM_MEMBERS = gql`
  query getClinicInfo {
    getClinicInfo {
      id
      teamMembers {
        id
        email
        name
      }
    }
  }
`;

export const GET_SUPER_CLINIC_TEAM_MEMBERS = gql`
  query getSuperClinicInfo {
    getSuperClinicInfo {
      id
      teamMembers {
        id
        email
        name
      }
    }
  }
`;

export const GET_SUPER_CLINIC_NOTI_AND_THUMB = gql`
  query getSuperClinicInfo {
    getSuperClinicInfo {
      id
      notifications {
        createdAt
        id
        params
        readAt
        recipientId
        recipientType
        type
      }
      profile {
        id
        avatar {
          thumb
        }
      }
    }
  }
`;

export const GET_CLINIC_NOTI_AND_THUMB = gql`
  query getClinicInfo {
    getClinicInfo {
      id
      termsConditions
      notifications {
        createdAt
        id
        params
        readAt
        recipientId
        recipientType
        type
      }
      profile {
        id
        avatar {
          thumb
        }
      }
    }
  }
`;

export const GET_COMPANY_TERMS_AND_THUMB = gql`
  query getCompanyInfo {
    getCompanyInfo {
      id
      termsConditions
      profile {
        id
        paymentMode
        avatar {
          thumb
        }
      }
    }
  }
`;

export const GET_PLANS_WITH_TITLE = gql`
  query planTemplates {
    planTemplates {
      id
      title
    }
  }
`;

export const GET_READING_TEMPLATES_WITH_TITLE = gql`
  query readingTemplates {
    readingTemplates {
      id
      title
      readings {
        id
        title
      }
    }
  }
`;

export const GET_PATIENTS_TO_SEND_SURVEY = gql`
  query patientsToSendSurvey {
    patients {
      id
      email
      name
      gender
      cardId
      status
    }
  }
`;

export const GET_ACTIVE_BREAKS = gql`
  query activeBreaks {
    activeBreaks {
      id
      title
    }
  }
`;

export const GET_ACTIVE_BREAK = gql`
  query activeBreak($activeBreakId: String!) {
    activeBreak(activeBreakId: $activeBreakId) {
      id
      title
      description
      tagColor
      bodyParts
      day {
        dayExercises {
          id
          exercisePosition
          exercise {
            id
            title
            image
          }
        }
      }
      backgroundImage {
        thumb
      }
    }
  }
`;

export const GET_AUDIOS = gql`
  query audios {
    audios {
      id
      title
      content
      file
      fileUs
    }
  }
`;

export const GET_AUDIO = gql`
  query audio($audioId: String!) {
    audio(audioId: $audioId) {
      id
      title
      content
      file
      fileUs
      thumbnailImage {
        thumb
      }
    }
  }
`;

export const GET_COMPANY_INVOICES = gql`
  query companyInvoices($period: String) {
    companyInvoices(period: $period) {
      paymentMode
      invoice {
        id
        amountDue
        status
        period
        currency
        invoiceItems {
          id
          recipient
          amount
          createdAt
          description
          recipientType
        }
      }
    }
  }
`;

export const GET_COMPANY_PAYMENT_INFO = gql`
  query getCompanyPaymentInfo {
    getCompanyInfo {
      id
      cardLast4
      cardBrand
      paymentMethodType
      profile {
        id
        currency
      }
    }
  }
`;

export const GET_SHORT_VIDEOS = gql`
  query shortVideos {
    shortVideos {
      id
      title
      description
      file
    }
  }
`;

export const GET_SHORT_VIDEO = gql`
  query shortVideo($shortVideoId: String!) {
    shortVideo(shortVideoId: $shortVideoId) {
      id
      title
      description
      file
      fileUs
      thumbnailImage {
        thumb
      }
      posterImage {
        thumb
      }
    }
  }
`;

export const READINGS_WITH_TYPE_NAME = gql`
  query getClinicReadings {
    getClinicReadings {
      id
      __typename
      title
      blockContent
      backgroundImage {
        thumb
      }
      thumbnailImage {
        thumb
      }
    }
  }
`;

export const AUDIOS_WITH_TYPE_NAME = gql`
  query audios {
    audios {
      id
      __typename
      title
      thumbnailImage {
        thumb
      }
    }
  }
`;

export const SHORT_VIDEOS_WITH_TYPE_NAME = gql`
  query shortVideos {
    shortVideos {
      id
      __typename
      title
      thumbnailImage {
        thumb
      }
    }
  }
`;

export const GET_BUILDER_PROGRAMS = gql`
  query builderPrograms {
    builderPrograms {
      id
      title
      description
      programLevels {
        id
      }
      backgroundImage {
        thumb
      }
    }
  }
`;

export const GET_BUILDER_PROGRAM = gql`
  query program($programId: String!) {
    program(programId: $programId) {
      id
      title
      description
      backgroundImage {
        thumb
      }
      programLevels {
        id
        number
        name
        goal: description
        programSessions {
          id
          number
          sessionExercises: programSessionExercises {
            id: sessionExerciseId
            title
            laps
            rest
            position
            exercises {
              position
              exercise {
                id
                title
                image
              }
            }
          }
          sessionSqueezes: programSessionSqueezes {
            id: sessionSqueezeId
            title
            position
            squeezes {
              position
              squeeze {
                id
                title
              }
            }
          }
        }
        readings {
          id
          __typename
          title
          backgroundImage {
            thumb
          }
          thumbnailImage {
            thumb
          }
        }
        audios {
          id
          __typename
          title
          thumbnailImage {
            thumb
          }
        }
        shortVideos {
          id
          __typename
          title
          thumbnailImage {
            thumb
          }
        }
      }
    }
  }
`;

export const GET_SQUEEZES = gql`
  query squeezes {
    squeezes {
      id
      title
      content
    }
  }
`;

export const GET_SQUEEZE = gql`
  query squeeze($squeezeId: String!) {
    squeeze(squeezeId: $squeezeId) {
      id
      title
      content
      audio
      audioUs
      jsonParse
    }
  }
`;

export const CHECK_COUPON_WEB = gql`
  query checkStripeCouponWeb($couponId: String!) {
    checkStripeCouponWeb(couponId: $couponId) {
      coupon
      errors
    }
  }
`;

export const GET_JSON_PARSE = gql`
  query squeeze($squeezeId: String!) {
    squeeze(squeezeId: $squeezeId) {
      id
      title
      jsonParse
      content
    }
  }
`;

export const GET_INDIVIDUAL = gql`
  query individual($individualId: String!) {
    individual(individualId: $individualId) {
      id
      email
      phone
      name
      gender
      birthday
      cardId
      status
      femaleProgram
      teamMember {
        email
        id
        name
      }
      bookings {
        id
        message
        startAt
        status
        versions {
          id
          logs
        }
      }
      currentBooking {
        id
        status
        startAt
      }
      assessment {
        id
        content
        diagnostic
        objective
        exerciseLevel
        functionality
        duration
        medicalHistory
        painLevel
        painLocalization
        answerFiles {
          id
          label
          file {
            original
          }
        }
      }
      patientFollowups {
        id
        content
        painLevel
        pillsLevel
        urinaryDisfunction
        functionality
        sleepQuality
        observations
        owner
        createdAt
        form {
          id
          title
          typeForm
        }
        answerFiles {
          id
          label
          file {
            original
          }
        }
      }
      surveys {
        id
        content
        form {
          id
          title
          createdAt
          content
        }
        status
        typeSurvey
        url
      }
      patientSurveys {
        id
        content
        form {
          id
          title
          createdAt
          content
        }
        answerFiles {
          id
          label
          file {
            original
          }
        }
        status
        typeSurvey
        url
        groupPoints {
          id
          points
        }
        totalPoints
      }
      patientPrograms {
        id
        status
        currentLevelNumber
        currentSessionNumber
        lastSessionCompletedAt
        program {
          id
          title
          duration
        }
      }
    }
  }
`;

export const GET_SURVEY_BY_PROGRAM = gql`
  query getSurveyByProgram($planKey: String!) {
    getSurveyByProgram(planKey: $planKey) {
      id
      surveyType
      title
      typeForm
      createdAt
      content
    }
  }
`;

export const GET_INDIVIDUAL_INFO = gql`
  query getIndividualInfo {
    getIndividualInfo {
      id
      name
      email
      currentProgram {
        id
        planKey
      }
    }
  }
`;

export const GET_MEDIA_FILES = gql`
  query mediaFiles {
    mediaFiles {
      id
      filename
      title
      altText
      fileUrl
      thumbUrl
    }
  }
`;
