/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { GrLinkNext } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import girls from 'assets/images/girls-smiling.webp';
import womanFeet from 'assets/images/woman-feet.webp';
import womanThinking from 'assets/images/woman-thinking.webp';
import painShape from 'assets/images/pelvic-shape.webp';
import painShapeBg from 'assets/images/pregnancy-shape-bg.webp';
import smile from 'assets/images/smile.svg';
import tablet from 'assets/images/tablet.svg';
import headphones from 'assets/images/headphones.svg';
import { useGetParams } from '../utils/useGetParams';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { ROUTES } from 'utils/constants';
import { KEYS } from '../utils/constants';
import { handleTrackEvent } from '../utils/handleTrackEvent';
import { useEvieOnboardingContext } from 'components/evieOnboarding/EvieOnboardingProvider';

import InfoScreen from '../InfoScreen';
import UserNameScreen from '../UserNameScreen';
import QuizStarter from '../QuizStarter';
import SolidButton from 'components/SolidButton';

const PainPath = ({ startSurvey }) => {
  const { t } = useTranslation();
  const { params } = useEvieOnboardingContext();
  const [path] = useLocalStorage(KEYS.PATH);

  const { step, push } = useGetParams();

  const handleNext = () => {
    handleTrackEvent('Step clicked', { number: step + 1 });
    push(`${ROUTES.EVIE_ONBOARDING}?${params}&step=${step + 1}`);
  };

  return (
    <>
      {step === 2 && (
        <InfoScreen staticimg>
          <LazyLoadImage effect="opacity" src={girls} alt="Girls smiling" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={smile} alt="smily" />
            </div>
            <h2 className="info-screen__footer_title">{t('evieOnboarding.infoScreen.pain.title1')}</h2>
            <p className="info-screen__footer_content">{t('evieOnboarding.infoScreen.pain.description1')}</p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 3 && (
        <InfoScreen staticimg>
          <LazyLoadImage effect="opacity" src={womanFeet} alt="Woman's feet" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={tablet} alt="tablet" />
            </div>
            <h2 className="info-screen__footer_title">{t('evieOnboarding.infoScreen.pain.title2')}</h2>
            <p className="info-screen__footer_content">{t('evieOnboarding.infoScreen.pain.description2')}</p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 4 && (
        <InfoScreen staticimg>
          <LazyLoadImage effect="opacity" src={womanThinking} alt="Woman Thinking" className="info-screen__bgimg" />
          <section className="info-screen__footer">
            <div className="info-screen__footer_topbg" />
            <div className="info-screen__footer_topbg second" />
            <div className="info-screen__footer_icon">
              <img src={headphones} alt="headphones" />
            </div>
            <h2 className="info-screen__footer_title">{t('evieOnboarding.infoScreen.pain.title3')}</h2>
            <p className="info-screen__footer_content">{t('evieOnboarding.infoScreen.pain.description3')}</p>
          </section>
          <SolidButton className="evie-next-icon welcome-screen__next" onClick={handleNext}>
            <GrLinkNext />
          </SolidButton>
        </InfoScreen>
      )}
      {step === 5 && <UserNameScreen />}
      {step === 6 && (
        <QuizStarter background="pain">
          <section className="quiz-starter__content">
            <div className="quiz-starter__pain">
              <img src={painShapeBg} alt="plant" className="quiz-starter__pain_bg" />
              <img src={painShape} alt="woman with baby" className="quiz-starter__pain_img" />
            </div>
            <h2 className="quiz-starter__content_title">{t('evieOnboarding.quizStarter.title3')}</h2>
            <p className="quiz-starter__content_description">{t('evieOnboarding.quizStarter.description')}</p>
          </section>
          <section className="quiz-starter__footer">
            <SolidButton className="evie-survey" onClick={() => startSurvey(path)}>
              {t('evieOnboarding.quizStarter.button')}
            </SolidButton>
          </section>
        </QuizStarter>
      )}
    </>
  );
};

PainPath.propTypes = {
  startSurvey: PropTypes.func.isRequired,
};

export default PainPath;
