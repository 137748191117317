/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty, pathOr } from 'ramda';
import amplitude from 'amplitude-js';

import { INDIVIDUAL_SIGNUP } from 'apollo/mutations';
import { ROUTES } from 'utils/constants';
import { useToggle } from 'utils/hooks/useToggle';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { handleTrackEvent } from './handleTrackEvent';
import { ONBOARDING_STATUS, KEYS } from './constants';
import { useGetParams } from './useGetParams';
import { useEvieOnboardingContext } from '../EvieOnboardingProvider';

export const useIndividualSignup = (setStatus) => {
  const { step, push } = useGetParams();
  const { params } = useEvieOnboardingContext();

  const [, setUserToken] = useLocalStorage(KEYS.USER_TOKEN);
  const [, setUserId] = useLocalStorage(KEYS.USER_ID);
  const [, setUserEmail] = useLocalStorage(KEYS.USER_EMAIL);

  const [individualSignup, { error }] = useMutation(INDIVIDUAL_SIGNUP);
  const [spinner, setSpinner] = useToggle();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (error && error.message) {
      setErrors([error.message]);
      setSpinner();
    }
  }, [error]);

  const handleIndividualSignup = async (userData) => {
    setSpinner();
    const { plan } = userData;

    const response = await individualSignup({ variables: { ...userData } });

    const signupErrors = pathOr([], ['data', 'individualSignup', 'errors'])(response);
    const userToken = pathOr('', ['data', 'individualSignup', 'token'])(response);
    const userId = pathOr('', ['data', 'individualSignup', 'individual', 'id'])(response);
    const userEmail = pathOr('', ['data', 'individualSignup', 'individual', 'email'])(response);
    const emptyErrors = isEmpty(signupErrors);

    setSpinner();

    if (emptyErrors) {
      localStorage.removeItem(KEYS.SOURCE);
      setUserToken(userToken);
      setUserId(userId);
      setUserEmail(userEmail);
      setStatus(ONBOARDING_STATUS.PLANS);
      amplitude.getInstance().setUserId(userId);
      const identify1 = new amplitude.Identify().set('plan', plan);
      amplitude.getInstance().identify(identify1);
      const identify2 = new amplitude.Identify().set('email', userData.email);
      amplitude.getInstance().identify(identify2);
      const identify3 = new amplitude.Identify().set('name', userData.name);
      amplitude.getInstance().identify(identify3);
      handleTrackEvent('Step clicked', { number: step + 1 });
      handleTrackEvent('Step plan', { number: 1 });
      handleTrackEvent('Signed up');
      push(`${ROUTES.EVIE_ONBOARDING}?${params}&step=${step + 1}&plan_step=1`);
    } else {
      setErrors(signupErrors);
    }
  };

  return { individualSignup: handleIndividualSignup, spinner, errors };
};
