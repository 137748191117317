/* eslint-disable import/order */
import React from 'react';
import { GrLinkNext } from 'react-icons/gr';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { ROUTES } from 'utils/constants';
import topknot from 'assets/images/topknot.png';
import { useGetParams } from '../utils/useGetParams';
import { CONDITIONS, KEYS } from '../utils/constants';
import { handleTrackEvent } from '../utils/handleTrackEvent';
import { useEvieOnboardingContext } from 'components/evieOnboarding/EvieOnboardingProvider';

import SolidButton from 'components/SolidButton';
import ContidionList from '../ContidionList';

const PathSelector = () => {
  const { push, step } = useGetParams();

  const { params } = useEvieOnboardingContext();
  const [, setPath] = useLocalStorage(KEYS.PATH);
  const [conditions, setConditions] = useLocalStorage(KEYS.CONDITIONS, []);

  const isDisabled = conditions === undefined || conditions.length === 0;

  const handleSelectCondition = (condition) => {
    setConditions((prevConditions) => {
      const conditionInConditions = prevConditions.find((currCondition) => currCondition === condition);
      if (conditionInConditions) {
        return prevConditions.filter((currCondition) => currCondition !== condition);
      }
      return [...prevConditions, condition];
    });
  };

  const handleNext = async () => {
    await setPath(() => {
      if (conditions.includes(CONDITIONS.PREGNANCY)) {
        return CONDITIONS.PREGNANCY;
      }
      if (conditions.includes(CONDITIONS.POSTPARTUM)) {
        return CONDITIONS.POSTPARTUM;
      }
      if (conditions.includes(CONDITIONS.INCONTINENCE)) {
        return CONDITIONS.INCONTINENCE;
      }
      return CONDITIONS.SEXUAL;
    });
    handleTrackEvent('Step clicked', { number: step + 1 });
    push(`${ROUTES.EVIE_ONBOARDING}?${params}&step=${step + 1}`);
  };

  return (
    <section className="path-selector">
      <ContidionList conditions={conditions} selectCondition={handleSelectCondition} />
      <img src={topknot} alt="topknot" className="welcome-screen__knot" />
      <SolidButton
        disabled={isDisabled}
        className={`evie-next-icon welcome-screen__next ${isDisabled ? 'evie-disabled' : ''}`}
        onClick={handleNext}
      >
        <GrLinkNext />
      </SolidButton>
    </section>
  );
};

export default PathSelector;
