/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { isEmpty } from 'ramda';
import { Slide } from 'react-awesome-reveal';

import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { KEYS } from '../utils/constants';
import { useValidateSignup } from '../utils/useValidateSignup';
import signupLogo from 'assets/images/signup-logo.webp';

import SolidButton from 'components/SolidButton';
import TextInput from 'components/Surveys/FormControlSurveys/FormComponents/TextInput';

const SignupScreen = ({ signup, errors }) => {
  const { t } = useTranslation();

  const signupValidation = useValidateSignup();

  const [userName] = useLocalStorage(KEYS.USER_NAME);
  const [path] = useLocalStorage(KEYS.PATH);
  const [content] = useLocalStorage(KEYS.CONTENT);
  const [source] = useLocalStorage(KEYS.SOURCE);

  const handleSubmit = (values) => {
    const { email } = values;
    signup({
      email: email.toLowerCase(),
      name: userName,
      plan: path,
      contentSurvey: content,
      source: source || 'web_flow',
    });
  };

  return (
    <section className="username-screen">
      <div className="sections__form username-screen__form">
        <img className="username-screen__form_logo" src={signupLogo} alt="Evie logo" />
        <h2 className="username-screen__form_label">{t('evieOnboarding.signup.title')}</h2>
        <p className="username-screen__form_description">{t('evieOnboarding.signup.description')}</p>
        <Formik initialValues={{ email: '' }} validationSchema={signupValidation} onSubmit={handleSubmit}>
          {(formData) => {
            const isDisabledNext = isEmpty(formData.values.email) || formData.errors.email;

            return (
              <Form>
                <div className="form-input username-screen__form_input">
                  <Slide duration={300}>
                    <TextInput
                      validate
                      placeholder={t('evieOnboarding.signup.emailPlaceholder')}
                      formikProps={formData}
                      name="email"
                    />
                  </Slide>
                </div>
                <SolidButton
                  disabled={isDisabledNext}
                  type="submit"
                  className={`evie-survey username-screen__form_nextbtn handle-sections ${
                    isDisabledNext ? 'evie-disabled' : ''
                  }`}
                >
                  {t('evieOnboarding.signup.submitButton')}
                </SolidButton>
              </Form>
            );
          }}
        </Formik>
        <div className="username-screen__form_error">
          {errors.map((error) => (
            <p key={error}>{error}</p>
          ))}
        </div>
      </div>
    </section>
  );
};

SignupScreen.propTypes = {
  signup: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SignupScreen;
