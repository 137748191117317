/* eslint-disable import/order */
import React from 'react';
import { Formik, Form } from 'formik';
import { isEmpty } from 'ramda';
import { IoMdArrowBack } from 'react-icons/io';
import { GrLinkNext } from 'react-icons/gr';

import { ROUTES } from 'utils/constants';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { useGetParams } from '../utils/useGetParams';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../utils/constants';
import { handleTrackEvent } from '../utils/handleTrackEvent';
import { useEvieOnboardingContext } from '../EvieOnboardingProvider';

import SolidButton from 'components/SolidButton';
import TextInput from 'components/Surveys/FormControlSurveys/FormComponents/TextInput';

const UserNameScreen = () => {
  const { t } = useTranslation();
  const { goBack, push, step } = useGetParams();
  const { params } = useEvieOnboardingContext();

  const [userName, setUserName] = useLocalStorage(KEYS.USER_NAME);

  const handleNext = async (values) => {
    const { userName: name } = values;
    await setUserName(name);
    handleTrackEvent('Step clicked', { number: step + 1 });
    push(`${ROUTES.EVIE_ONBOARDING}?${params}&step=${step + 1}`);
  };

  return (
    <section className="username-screen">
      <div className="info-screen__goback">
        <IoMdArrowBack className="info-screen__goback_icon" onClick={goBack} />
      </div>
      <div className="sections__form username-screen__form">
        <h2 className="username-screen__form_label">{t('evieOnboarding.userName')}</h2>
        <Formik initialValues={{ userName: userName || '' }} onSubmit={handleNext}>
          {(formData) => {
            const isDisabled = isEmpty(formData.values.userName) || formData.isSubmitting;
            return (
              <Form>
                <div className="form-input username-screen__form_input">
                  <TextInput formikProps={formData} name="userName" />
                </div>
                <SolidButton
                  disabled={isDisabled}
                  type="submit"
                  className={`evie-next-icon username-screen__form_nextbtn ${isDisabled ? 'evie-disabled' : ''}`}
                >
                  <GrLinkNext />
                </SolidButton>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default UserNameScreen;
