/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Lottie from 'lottie-react';
import { isEmpty } from 'ramda';
import amplitude from 'amplitude-js';

import { GET_SURVEY_BY_PROGRAM, GET_INDIVIDUAL_INFO } from 'apollo/queries';
import { ONBOARDING_STATUS, KEYS } from 'components/evieOnboarding/utils/constants';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { useGetParams } from 'components/evieOnboarding/utils/useGetParams';
import { ROUTES, ENVIRONMENT } from 'utils/constants';
import { useTabSettings } from 'utils/hooks/useTabSettings';
import { useFcPixel } from 'utils/hooks/useFcPixel';
import { useIndividualSignup } from 'components/evieOnboarding/utils/useIndividualSignup';
import { useToggle } from 'utils/hooks/useToggle';
import { handleTrackEvent } from 'components/evieOnboarding/utils/handleTrackEvent';
import { REACT_APP_AMPLITUDE_API_KEY, REACT_APP_ENV } from 'config';
import heartLoader from 'assets/heart-loader.json';

import Layout from 'components/evieOnboarding/Layout';
import Welcome from 'components/evieOnboarding/Welcome';
import Survey from 'components/evieOnboarding/Survey';
import SignupScreen from 'components/evieOnboarding/SignupScreen';
import Plans from 'components/evieOnboarding/Plans';
import LoadingScreen from 'components/evieOnboarding/LoadingScreen';
import { EvieOnboardingProvider, useEvieOnboardingContext } from 'components/evieOnboarding/EvieOnboardingProvider';

import './evie-onboarding.scss';

const EvieProgramWrapper = () => (
  <EvieOnboardingProvider>
    <EvieOnboarding />
  </EvieOnboardingProvider>
);

const EvieOnboarding = () => {
  useTabSettings('Evie', '/evie-favicon.ico');
  const { params, setParams } = useEvieOnboardingContext();
  const { urlParams, source, step, group, question, urlToken, push } = useGetParams();

  const [status, setStatus] = useLocalStorage(KEYS.ONBOARDING_STATUS, ONBOARDING_STATUS.WELCOME);
  const [, setContent] = useLocalStorage(KEYS.CONTENT);
  const [userToken, setUserToken] = useLocalStorage(KEYS.USER_TOKEN);
  const [entitlements] = useLocalStorage(KEYS.ENTITLEMENTS);
  const [, setSource] = useLocalStorage(KEYS.SOURCE);
  const [, setUserId] = useLocalStorage(KEYS.USER_ID);
  const [path, setPath] = useLocalStorage(KEYS.PATH);
  const [, setEmail] = useLocalStorage(KEYS.USER_EMAIL);

  const { loading: individualLoading, data: individualData = {} } = useQuery(GET_INDIVIDUAL_INFO, {
    skip: !urlToken, // Skip the query if token is not present
    context: {
      headers: {
        authorization: urlToken ? `Bearer ${urlToken}` : '',
        role: 'individual',
      },
    },
  });
  const { getIndividualInfo = {} } = individualData;
  const { id: individualId = null, email = '', currentProgram: { planKey = '' } = {} } = getIndividualInfo;
  const [getSurvey, { loading, error, data = {} }] = useLazyQuery(GET_SURVEY_BY_PROGRAM);
  const { getSurveyByProgram = {} } = data;
  const { individualSignup, spinner, errors } = useIndividualSignup(setStatus);

  amplitude.getInstance().init(REACT_APP_AMPLITUDE_API_KEY, null, { autocapture: true, includeUtm: true });
  const identify = new amplitude.Identify().set('test', REACT_APP_ENV === ENVIRONMENT.DEVELOPMENT);
  amplitude.getInstance().identify(identify);

  const [isSendingSurvey, setIsSendingSurvey] = useToggle();

  useFcPixel();

  useEffect(() => {
    if ((userToken || urlToken) && !urlParams.includes('plan_step')) {
      setParams(urlParams);
    }
  }, []);

  useEffect(() => {
    if (individualId) {
      setUserId(individualId);
      amplitude.getInstance().setUserId(individualId);
    }
    if (planKey) {
      setPath(planKey);
    }
    if (email) {
      setEmail(email);
    }
  }, [individualId]);

  useEffect(() => {
    if (!path) {
      handleTrackEvent('Initial visit');
    }
  }, [path]);

  useEffect(() => {
    if (source) {
      setSource(source);
    }
  }, [source]);

  useEffect(() => {
    if (!userToken) {
      setStatus(ONBOARDING_STATUS.WELCOME);
      push(`${ROUTES.EVIE_ONBOARDING}?${params}`);
    }
  }, [source, userToken, params]);

  useEffect(() => {
    if (userToken || urlToken) {
      if (urlToken) {
        setUserToken(urlToken);
      }
      setStatus(ONBOARDING_STATUS.PLANS);
      push(`${ROUTES.EVIE_ONBOARDING}?${params}&plan_step=1`);
    }
  }, [urlToken, userToken, params]);

  useEffect(() => {
    if (entitlements === 'Premium') {
      setStatus(ONBOARDING_STATUS.PLANS);
      push(ROUTES.EVIE_DOWNLOAD);
    }
  }, [entitlements]);

  useEffect(() => {
    if (!isEmpty(data) && !loading && !error) {
      setStatus(ONBOARDING_STATUS.SURVEY);
    }
  }, [data, loading, error]);

  const handleStartSurvey = (selectedPath) => {
    getSurvey({ variables: { planKey: selectedPath } });
    handleTrackEvent('Step clicked', { number: step + 1 });
    handleTrackEvent('Survey started');
    push(`${ROUTES.EVIE_ONBOARDING}?${params}&step=${step + 1}&group=${group}&question=${question}`);
  };

  const handleCompleteSurvey = async (surveyData) => {
    setIsSendingSurvey(true);
    await setContent(surveyData);
    await new Promise((resolve) => {
      setTimeout(() => {
        setIsSendingSurvey(false);
        resolve();
      }, 10000);
    });
    setStatus(ONBOARDING_STATUS.SIGN_UP);
    handleTrackEvent('Step clicked', { number: step + 1 });
    handleTrackEvent('Survey finished');
    push(`${ROUTES.EVIE_ONBOARDING}?${params}&step=${step + 1}`);
  };

  return (
    <>
      <Layout>
        {status === ONBOARDING_STATUS.WELCOME && <Welcome startSurvey={handleStartSurvey} />}
        {status === ONBOARDING_STATUS.SURVEY && !isEmpty(data) && (
          <Survey survey={getSurveyByProgram} completeSurvey={handleCompleteSurvey} />
        )}
        {status === ONBOARDING_STATUS.SIGN_UP && <SignupScreen signup={individualSignup} errors={errors} />}
        {status === ONBOARDING_STATUS.PLANS && <Plans userId={individualId} path={planKey} />}
        {isSendingSurvey && <LoadingScreen />}
      </Layout>
      {(loading || spinner || individualLoading) && (
        <div className="onboarding-layout__lottie">
          <Lottie animationData={heartLoader} loop style={{ width: 140, height: 110 }} />
        </div>
      )}
    </>
  );
};

export default EvieProgramWrapper;
